import { v4 as uuid } from 'uuid'

import { logError } from './error'
import type {
  GlobalApiBaseErrorCode,
  GlobalApiServerError as GlobalApiErrorData,
} from './globalApiErrorHandler'

export const ErrorCodes = {
  UNKNOWN_ERROR: 'unknownError',
  PERMISSION_ERROR: 'permissionError',
  NETWORK_ERROR: 'networkError',
  VALIDATION_ERROR: 'validationError',
  UI_ERROR: 'uiError',
  SECTION_ERROR: 'sectionError',
  CONTRACT_ERROR: 'contractError',
  ORDER_DATA_ADAPTER_ERROR: 'orderDataAdapterError',
  UNKNOWN_GLOBAL_API_ERROR: 'unknownGlobalApiError',
  GLOBAL_API_ERROR: 'globalApiError',
  SERVER_COMPONENT_ERROR: 'serverComponentError',
  CONTENTFUL_API_ERROR: 'contentfulApiError',
} as const

export type ErrorCodesMapping = (typeof ErrorCodes)[keyof typeof ErrorCodes]

type BaseErrorProps<T = Record<string, unknown>> = {
  message: string
  code: ErrorCodesMapping
  additionalData: T
  name?: string
}

export class BaseError<T = Record<string, unknown>> extends Error {
  code: ErrorCodesMapping
  additionalData: T
  readonly id: string

  constructor(props: BaseErrorProps<T>) {
    const { message, code, additionalData, name } = props
    super(message)
    this.id = uuid()
    this.code = code
    this.additionalData = additionalData
    this.name = name || 'UnknownError'

    // To include the message in the JSON representation
    Object.defineProperty(this, 'message', {
      enumerable: true,
    })
  }

  toJSON() {
    return {
      id: this.id,
      code: this.code,
      message: this.message,
      name: this.name,
      additionalData: this.additionalData,
    }
  }

  public report() {
    logError(this)
  }
}

export class ReportableError<T> extends BaseError<T> {
  constructor(props: BaseErrorProps<T>) {
    super(props)
    this.report()
  }
}

class NonReportableError<T> extends BaseError<T> {
  constructor(props: BaseErrorProps<T>) {
    super(props)
  }
}

export class NetworkError extends ReportableError<{ httpCode: number | undefined }> {
  constructor(httpCode: number | undefined, message: string) {
    super({
      message,
      code: ErrorCodes.NETWORK_ERROR,
      name: 'NetworkError',
      additionalData: { httpCode },
    })
  }
}

export class PermissionError extends ReportableError<Record<string, unknown>> {
  constructor(message: string) {
    super({
      message,
      name: 'PermissionError',
      code: ErrorCodes.PERMISSION_ERROR,
      additionalData: {},
    })
  }
}

export class ValidationError extends NonReportableError<{ field: string; issues: string[] }> {
  constructor(field: string, issues: string[], message: string) {
    super({
      message,
      name: 'ValidationError',
      code: ErrorCodes.VALIDATION_ERROR,
      additionalData: { field, issues },
    })
  }
}

export class UiError extends NonReportableError<Record<string, unknown>> {
  constructor(message: string) {
    super({
      message,
      name: 'UserInterfaceError',
      code: ErrorCodes.UI_ERROR,
      additionalData: {},
    })
  }
}

export class SectionError extends ReportableError<{ sectionType: string }> {
  constructor(sectionType: string, message: string) {
    super({
      message,
      name: 'SectionError',
      code: ErrorCodes.SECTION_ERROR,
      additionalData: { sectionType },
    })
  }
}

export class ServerComponentError extends ReportableError<{
  sectionType: string
  entryId?: string
}> {
  constructor(sectionType: string, message: string, entryId?: string) {
    super({
      message,
      name: 'ServerComponentError',
      code: ErrorCodes.SERVER_COMPONENT_ERROR,
      additionalData: { sectionType, entryId },
    })
  }
}

export class PageError extends ReportableError<Record<string, unknown>> {
  constructor(pageType: string, message: string) {
    super({
      message,
      name: 'PageError',
      code: ErrorCodes.SECTION_ERROR,
      additionalData: { pageType },
    })
  }
}
export class ContractOrderError extends ReportableError<Record<string, unknown>> {
  constructor(message: string) {
    super({
      message,
      name: 'ContractOrderError',
      code: ErrorCodes.CONTRACT_ERROR,
      additionalData: {},
    })
  }
}

export class OrderDataAdapterError extends ReportableError<Record<string, unknown>> {
  constructor(message: string) {
    super({
      message,
      name: 'OrderDataAdapterError',
      code: ErrorCodes.ORDER_DATA_ADAPTER_ERROR,
      additionalData: {},
    })
  }
}

export class UnknownGlobalApiError extends ReportableError<Record<string, unknown>> {
  constructor(message: string) {
    super({
      message,
      name: 'UnknownGlobalApiError',
      code: ErrorCodes.UNKNOWN_GLOBAL_API_ERROR,
      additionalData: {},
    })
  }
}

export class GlobalApiError<E> extends NonReportableError<{
  apiError: GlobalApiErrorData<E>
}> {
  constructor(props: { apiError: GlobalApiErrorData<E> }) {
    const { apiError } = props
    super({
      message: apiError.message || 'Global API error - no message',
      name: 'GlobalApiError',
      code: ErrorCodes.GLOBAL_API_ERROR,
      additionalData: {
        apiError,
      },
    })

    if (apiError.code === 'SERVER_ERROR' || apiError.code === 'NETWORK_ERROR') {
      this.report()
    }
  }
}

export class ContentfulApiError extends ReportableError<{
  queryVariables: string
  apiErrorMessage: string
  contentfulRequestId?: string
  queryCost?: string
  persistedQueryHash?: string
}> {
  constructor(props: {
    message: string
    queryVariables: Record<string, unknown>
    apiErrorMessage: string
    contentfulRequestId?: string
    queryCost?: string
    persistedQueryHash?: string
  }) {
    const {
      apiErrorMessage,
      message,
      queryVariables,
      contentfulRequestId,
      persistedQueryHash,
      queryCost,
    } = props
    super({
      message,
      name: 'ContentfulApiError',
      code: ErrorCodes.CONTENTFUL_API_ERROR,
      additionalData: {
        queryVariables: JSON.stringify(queryVariables),
        apiErrorMessage,
        contentfulRequestId,
        persistedQueryHash,
        queryCost,
      },
    })
  }
}

export const isGlobalApiError = <E = GlobalApiBaseErrorCode>(
  error: unknown,
): error is GlobalApiError<E> => (error as GlobalApiError<E>) instanceof GlobalApiError
